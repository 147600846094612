@import 'src/styles';

.popup {
  width: 100%;
  background-color: #F7F7F7;
  border-radius: 40px 40px 0 0;
  outline: none;
  border: 0;
  height: fit-content;
  bottom: 0;
  position: absolute;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .img {
    width: 100%;
    max-width: 345px;
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .title {
    font-weight: 700;
    font-size: $fontSize26;
    line-height: $fontSize32;
    color: $mineShaft;
    margin: 0 25px;
  }

  .text {
    font-size: $fontSize17;
    line-height: $fontSize26;
    color: $mineShaft;
    margin: 20px 25px 40px;
  }

  .close {
    position: absolute;
    right: 25px;
    top: 25px;

    path {
      fill: #D8D8D8;
    }

  }

  .button {
    margin-bottom: 12px;
  }

  &.dark {
    background-color: #2D5668;

    .title {
      color: #F2E6C4;
    }

    .text {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.30);
  z-index: 23;

  &.dark {
    background-color: rgba(21, 43, 53, 0.8);
  }
}