@import "core/styles";

// colors
$gold: #d0b894;
$secondDarkGreen: #142b35;
$lightNeonGreen: #00eedb;
$darkNeonGreen: #27baa7;
$lightGreen: #3d7481;
$thirdDarkGreen: #1e3b48;
$deepDarkGreen: #142b35;

$secondaryGray: #c7c7c7;
$oldLace: #fdeee4;
$mystic: #d9eae8;
$lightGreenBg: #e3f1ee;
$light-beige: #fef4ef;
$lightRed: #f9e2d6;
$lightBlue: #d8e9ef;
$secondLightBlue: #f2fbfa;
$beige: #f2f2f2;
$grayLight: #e0e0e0;
$lightSand: #f2e6c4;
$rumSwizzle: #faf6e8;
$goldSand: #a39376;
$deepSeaGreen: #0d6056;
$genoa: #16786c;
$breakerBay: #69a79b;
$redDamask: #dc7f4b;
$darkRed: #e88c5b;
$apricot: #ee8f61;
$red: #eda882;
$mineShaft: #2d2d2d;
$primaryText: #333333;
$scorpion: #565656;
$darkGrayBlue: #335662;
$darkGrayBlue: #335662;
$darkDeepGreen: #1e3b48;
$cocoa: #785643;
$romanCoffee: #83614c;
$graySpecial: #575757;
$graySpecialTwo: #797979;
$gray: #818181;
$lightCocoa: #c0afa1;
$dark-beige: #c7b8ab;
$eggWhite: #fff2c4;
$lightYellow: #fef7db;
$darkPowder: #fcece3;
$green: #bed7d4;
$cloudyBlue: #b9d7d1;
$darkGreen: #a6c8c4;
$blue: #7eb6c9;
$darkBlue: #3e89a2;
$yellow: #fde387;
$darkYellow: #e8ca61;
$blackColor: #000000;
$ocean: #376478;
$lightFire: #f27067;
$middleGray: #4b626d;
$lowGray: #2d5668;
$breeze: #45d5c1;
$darkBreeze: #298784;
$lightBreeze: #7da9a2;
$trans: transparent;

//summary
$summaryTitle: $gold;
$summarySubtitle: $lightSand;
$summaryBgBlockColor: $darkDeepGreen;
$summaryBgActiveButtonColor: $ocean;
$summaryBgButtonColor: $darkGreen;
$summaryBgDiscount: $lightFire;
$summaryBgPerWeekButton: $middleGray;
$summaryText: $goldSand;
$summaryZodiacIconColor: $lowGray;
$summaryTerminateInputColor: $darkBreeze;
$summaryTerminateButton: $breeze;
$summaryTerminateLogo: $lightBreeze;

// preloader
$preloaderColor: #16786c;
$preloaderBg: #142b3577;

//shadow & opacity
$blackWithOpacity: rgba(0, 0, 0, 0.5);
$grayOpacity: rgba(45, 45, 45, 0.4);
$boxShadowColor: rgba(183, 181, 170, 0.4);
$boxShadow: 0 1px 4px 0 rgba(183, 181, 170, 0.4);
$boxShadowTitle: 0 1px 2px rgba(0, 0, 0, 0.2);
$shadowColor: rgba(183, 181, 170, 0.3);

// goal component
$firstGoalBg: $lightGreenBg;
$firstLabelBg: $green;
$firstLabelArcBg: $darkGreen;
$secondGoalBg: $lightRed;
$secondLabelBg: $red;
$secondLabelArcBg: $darkRed;
$thirdGoalBg: $lightBlue;
$thirdLabelBg: $blue;
$thirdLabelArcBg: $darkBlue;
$fourthGoalBg: $lightYellow;
$fourthLabelBg: $yellow;
$fourthLabelArcBg: $darkYellow;

// base
$mainBgColor: $darkGreen;
$inactiveColor: $secondDarkGreen;
$activeColor: $darkNeonGreen;
$backgroundBlockColor: $thirdDarkGreen;

// buttons
$buttonLightBgColor: $lightNeonGreen;
$buttonDarkBgColor: $darkNeonGreen;
$disabledButtonBg: $oldLace;
$disabledButtonColor: $dark-beige;
$disabledEmailButtonBg: $light-beige;
$disabledEmailButtonColor: $lightCocoa;
$buttonWrapperBg: $secondLightBlue;

// input
$inputTextColor: $white;
$inputPlaceholderColor: $gold;
$inputBorder: $darkNeonGreen;

// links
$linkColor: #2879d8;

// form
$colorLabel: #333333;
$colorFocus: #2879d8;
$colorTextField: #333333;
$colorPlaceholder: #dbdbdb;
$colorBorderField: rgba(184, 185, 195, 0.3);
$colorBgSuccesMessage: #f2ffe5;

$maxWidthSummary: 345px;

$inputColor: $secondaryGray;
$inputCaretColor: $genoa;

$checkboxMarkBackground: $darkNeonGreen;
$checkboxMarkCheckedBackground: $darkNeonGreen; // TODO

// button
$buttonBackground: linear-gradient(
  2.59deg,
  $buttonLightBgColor -78%,
  $buttonDarkBgColor 145%
);
$buttonFontFamily: $fontFamilyPhilosopher;
$buttonFontSize: $fontSize24;
$buttonFontWeight: $fwstb;

$payModalPrimaryColor: #16786c;

// title
$titleH1Color: $lightSand;
$titleH1FontFamily: $fontFamilyPhilosopher;
$titleH2Color: $lightSand;
$titleH2FontFamily: $fontFamilyPhilosopher;
$titleH3Color: $gold;
$titleH3FontFamily: $fontFamilyPhilosopher;
$textColor: $gold;

// subscription
$plansPrimaryBg: #2b5567;
$plansInactiveBg: $darkDeepGreen;
$plansPrimaryColor: $darkNeonGreen;
$plansInactiveColor: $middleGray;
$plansPrimaryBorderColor: $plansPrimaryColor;
$plansInactiveBorderColor: $ocean;
$plansPrimaryTitleColor: $titleH1Color;
$plansInactiveTitleColor: $plansInactiveColor;
$plansPrimaryTextColor: white;
$plansInactiveTextColor: $plansInactiveColor;
$plansPrimaryPriceColor: $white;
$plansInactivePriceColor: transparentize($white, 0.2);
$plansBadgeColor: $lightFire;
$fixedButtonBoxBg: $thirdDarkGreen;
$fixedButtonDiscountColor: $gold;
$policyCheckBoxTextColor: $white;

$radioPlansInactiveBg: transparent;
$radioPlansInactiveBorderColor: #1e3b48;
$radioPlansActiveBg: #2b5567;
$radioPlansActiveBorderColor: $white;
$radioPlansActiveTitleColor: #f2e6c4;
$radioPlansActiveColor: #27baa7;
$radioPlansColor: $white;
$radioPlansPriceActiveColor: #f27067;
$radioPlansWeekInactiveBg: #4a626d;

// manage

$manageBg: #101d23;
$manageAccentColor: #d0b894;
$manageMainColor: $white;
$manageModalCloseIcon: #f2e6c4;
$manageModalBg: #2d5668;
$manageCheckmark: linear-gradient(
  134.16deg,
  #e5a87f 18.5%,
  #ecbf98 31.9%,
  #f7e6c4 56.46%,
  #e5a87f 92.92%
);
$manageCheckboxBg: #1e3b48;
$manageCheckboxChecked: #e2cfa4;
$manageTextareaBorder: #19cebb;
$manageTextareaBg: #1e3b48;
$manageCurrentPlanBg: $plansPrimaryBg image-set(
  "src/screens/manage/images/plan.webp" 1x,
  "src/screens/manage/images/plan@2x.webp" 2x,
  "src/screens/manage/images/plan@3x.webp" 3x
) no-repeat left top;

// terminate

$terminateBg: $backgroundBlockColor;
$terminateTitleColor: $gold;
$terminateTextColor: $gold;
$terminateInputColor: $gold;
$terminateInputBg: none;
$terminateConfirmBg: #142b35;
$terminateConfirmHeaderBg: #1e3b48;
$terminateConfirmBlur: linear-gradient(
  135.18deg,
  rgba(0, 255, 195, 0.7) 3.2%,
  rgba(78, 136, 222, 0.7) 99.69%
);
$terminateConfirmHeaderColor: #f2e6c4;
$terminateConfirmWarningBg: #1e3b48;
$terminateConfirmWarningColor: rgba(255, 255, 255, 0.8);
$terminateConfirmFeatureBg: transparent;
$terminateConfirmFeatureBorder: #1e3b48;
$terminateConfirmFeatureTitleColor: #f2e6c4;
$terminateConfirmFeatureSubtitleColor: rgba(255, 255, 255, 0.7);
$terminateConfirmFeatureShadow: none;
$terminateConfirmFeaturesColor: #d0b894;
$terminateConfirmButtonColor: #ffffff;
$terminateConfirmButtonBorder: #19cebb;

// injected payment panel
$spmFontFamily: $fontFamilyPhilosopher;

// full payment modal
$fpmBackgroundColor: $thirdDarkGreen;
$fpmFontFamily: $fontFamilyPhilosopher;

$maxWidthDefault: 400px;

// questions
$questionTitleBgColor: #3d7481;
$questionTitleColor: $white;
$questionActiveTitleColor: #d0b894;
$questionActiveTitleBorderColor: #d0b894;

// injected payment method
$injectedPaymentMethodActiveColor: #14D5C2;
$injectedPaymentMethodCheckActiveColor: #14D5C2;
$injectedPaymentMethodColor: #1E3B48;
$injectedPaymentMethodArrowColor: #1E3B48;
$injectedPaymentMethodDark: flex;
$injectedPaymentMethodLight: none;

// trials left
$trialsLeftBadgeColor: $white;
$trialsLeftBadgeBg: #f27067;
$trialsLeftTextColor: #f27067;

// payment safe
$paymentSafeDark: inline-block;
$paymentSafeLight: none;
$paymentSafeColor: #FAF6E8;

$paymentOverlay: $backgroundBlockColor;